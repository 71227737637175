.page-content {
  font-size: 1.143em;
  margin-bottom: 30px;

  p, ol, ul, pre {
    margin-bottom: 20px;
  }

  p + ul {
    margin-top: -10px;
  }
}

.pagelist {
  list-style-type: none;
  margin: 0 0 20px 0;
  padding: 0;
  border-left:1px solid #ccc;
  padding-left: 5px;

  li {
    display: inline-block;
    border-right:1px solid #ccc;
    padding-right:5px;
    margin-right:5px;

    &.current_page_item a {
      color:#000;
    }

  }
}

.toimialaselect {
  padding: 0 0 30px 0;

  select {
    width: 100%;
    font-size: 1.143em;
  }

}

.yrityslinkki {
  display: none;
  float: left;
  margin: 0 15px 15px 0;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1.286em;
  background-color: #f8f8f8;
  color: #000;
  width: 100%;

  @media (min-width: 500px) {
    width: auto;
  }

  &:hover, &:focus {
    text-decoration: none;
    color: #fff;
    background-color: #0a79bd;
  }
}
.lettertitle {
  &:first-child {
    margin-top: 0;
  }
}

.toimialalinkki {
    color: #8c8c8c;
    font-weight: 300;

}
.abclinkki {
  font-weight: 600;
}

.yrityslajittelu {
  font-weight: 300;
  display: block;

  @media (min-width: $screen-sm-min) {
    border-left: 1px solid #ccc;
    padding-left: 10px;
    margin-left: 10px;
    display: inline-block;
  }

  &.active {
    color: #000;
  }

}

.yrityswrap {
  margin-bottom: 10px;
}
.yritystitle {
  margin-bottom: 0;
  font-size: 1.143em;
}
.lyhyt-kuvaus {
  margin-bottom: 0;
  color: #8c8c8c;
  font-weight: 300;
  font-size: 1em;
  line-height: 1;
}
