

.page-header {
  margin: 0 0 30px;

  h1 {
    margin: 0;
  }
}

.hentry {
  margin-bottom: 30px;
  padding-bottom: 0px;
  float: left;
  width: 100%;
  @media (min-width: $screen-sm-min) {
    border-bottom: 1px solid #eee;
  }

  .single & {
    float: none;
  }
}

.entry-title {
  margin-top: 0;
  margin-bottom: 0;
}

.yritys-title {
  background-color: $brand-primary;
  color: #fff;
  padding: 10px 20px;
  margin: 0 0 0 0;
  font-weight: 300;

  span {
    font-size: 0.6em;
    padding-left: 10px;
    color: #bcd3f1;
  }
}

.yritys-toimiala {
    margin: 0 0 20px 0;
    background-color: #e8e8e8;
    padding: 8px 20px;
    font-weight: 300;

    a {
      color: #000;
    }
}

.yritys-avainsana {
    margin: -19px 0 20px 0;
    background-color: #e8e8e8;
    padding: 8px 20px;
    font-weight: 300;

    a {
      color: #000;
    }
}


.entry-content {
  font-size: 1.143em;
  // line-height: 1.1em;

  p, ol, ul, pre {
    margin-bottom: 20px;
  }

  p + ul {
    margin-top: -10px;
  }

  .yrityskuva {
    margin-bottom: 20px;
  }

  .short-content {
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
    padding: 20px;
    background-color: #eaeaea;
  }

}

.updated {
  margin-bottom: 20px;
  display: block;
  // font-style: italic;
}
