@charset "UTF-8";

// Default Variables

$slick-font-path: "../fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "../images/" !default;
$slick-arrow-color: #ccc !default;
$slick-dot-color: white !default;
$slick-dot-color-active: red !default;
$slick-prev-character: "←" !default;
$slick-next-character: "→" !default;
$slick-dot-character: "•" !default;
$slick-dot-size: 20px !default;
$slick-opacity-default: 0.8 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    }
    @else {
        @return url($slick-loader-path + $url);
    }
}

@function slick-font-url($url) {
    @if function-exists(font-url) {
        @return font-url($url);
    }
    @else {
        @return url($slick-font-path + $url);
    }
}

/* Slider */

.slick-list {
    .slick-loading & {
        background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
    }
}

/* Icons */
@if $slick-font-family == "slick" {
    @font-face {
        font-family: "slick";
        src: slick-font-url("slick.eot");
        src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
        font-weight: normal;
        font-style: normal;
    }
}

/* Arrows */

.slick-slider{
    .slick-prev, .slick-next{
        // display:none !important;
    }
    &:hover{
        .slick-prev, .slick-next{
            @media (min-width: $screen-sm-min) {
              visibility: visible;
              opacity: 1;
            }
        }
    }
}

.slick-prev,
.slick-next {
  display: none !important;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: visibility 0s, opacity 0.5s linear;
  transition: visibility 0s, opacity 0.5s linear;
  line-height:0;
  font-size:0;
  cursor:pointer;
  background:0 0;
  color:transparent;
  top:50%;
  margin-top:-10px\9;
  -webkit-transform:translate(0,-50%);
  -ms-transform:translate(0,-50%);
  -o-transform:translate(0,-50%);
  transform:translate(0,-50%);
  padding:0;
  border:none;
  outline:0;
  z-index:10;
  @media (min-width: $screen-sm-min) {
    display: block !important;
    position:absolute;
    height:50px;
    width:50px;
  }

    &:hover, &:focus {
        outline: none;
        background: transparent;
        color: transparent;
        &:before {
            opacity: $slick-opacity-on-hover;
        }
    }
    &.slick-disabled:before {
        opacity: $slick-opacity-not-active;
    }
}

.slick-prev:before, .slick-next:before {
    font-family: $slick-font-family;
    font-size: 50px;
    line-height: 1;
    color: $slick-arrow-color;
    opacity: $slick-opacity-default;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
    left: 15px;
    [dir="rtl"] & {
        left: auto;
        right: 15px;
    }
    &:before {
        content: $slick-prev-character;
        [dir="rtl"] & {
            content: $slick-next-character;
        }
    }
}

.slick-next {
    right: 15px;
    [dir="rtl"] & {
        left: 15px;
        right: auto;
    }
    &:before {
        content: $slick-next-character;
        [dir="rtl"] & {
            content: $slick-prev-character;
        }
    }
}

/* Dots */

.slick-slider {
  margin-bottom: 0;
  background-color: transparent;
}

.slick-dots {
    bottom:0;
    position:relative;
    margin-bottom:0;
    list-style:none;
    list-style-type:none;
    display:block;
    padding:0;
    width:100%;
    text-align:center;
    @media (min-width: $screen-sm-min) {
      background-image:none;
      position:absolute;
      margin-bottom:10px;
    }
    li {
      position:relative;
      display:inline-block;
      height:21px;
      width:21px;
      margin:5px;
      padding:0;
      cursor:pointer;
        @media (min-width: $screen-sm-min) {
          margin: 5px;
        }
        button {
            border:0;
            background-color: rgba(202, 202, 202, 0.9);
            background: rgba(202, 202, 202, 0.9);
            display:block;
            height:21px;
            width:21px;
            outline:0;
            line-height:0;
            font-size:0;
            color:transparent;
            padding:0;
            cursor:pointer;
            border: 1px solid #000;
            &:hover, &:focus {
              outline: none;
            }
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                content: '';
                height: 25px;
                width: 16px;
                font-family: $slick-font-family;
                font-size: $slick-dot-size;
                line-height: 25px;
                text-align: center;
                color: $slick-dot-color;
                opacity: $slick-opacity-not-active;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }
        &.slick-active button {
            background-color: rgba(69, 120, 185, 1);
            background: rgba(69, 120, 185, 1);
        }
    }
}

.slidetext {

  text-align: center;
  width: 100%;
  color: #fff;
  background-color: rgba(202, 202, 202, 0.8);
  background: rgba(202, 202, 202, 0.8);
  font-size: 1.3em;
  line-height: 1;
  padding: 7px;
  font-weight: 700;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 1);

  @media (min-width: $screen-sm-min) {
  position: absolute;
  bottom: 50px;
  font-size: 1.6em;

  }

  @media (min-width: $screen-md-min) {
  font-size: 2em;

  }

  @media (min-width: $screen-lg-min) {
  font-size: 2.143em;

  }

}

.slide-link {
  display: block;
  position: relative;
}
