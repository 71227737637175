body#tinymce {
  margin: 12px !important;
  height:auto !important;

  h1,h2,h3,h4,h5 {
    &:first-child {
      margin-top: 0;
    }
  }
}
