.sidebar {
  clear: both;

  @media (min-width: $screen-sm-min) {
    clear: none;;
  }

  &__ajankohtaista {

    // background-color: #ededed;
    margin-bottom: 30px;

    h3 {
      // background-color: $brand-primary;
      color: #000080;
      padding: 0 25px;
      font-size: 24px;
      margin: 0;
    }

    .padding {
      padding: 10px 25px 25px 25px;
      font-size: 16px;

      h4 {
        font-size: 20px;
        text-transform: uppercase;
        margin-top:20px;
        color: #000;
      }

      a {
        color: #000080;
      }

      p {
        line-height: 1.2;
        color: #000080;
      }

    }

    .padding_notitle {
      padding: 25px;

    }

  }

}


#map {
min-height: 400px;
  // height: 100%;
  margin-bottom: 25px;
}


.yritys-details {
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding: 16px 20px;
  border: 1px solid #ccc;
  font-size: 1.143em;
  // float: left;
  // width: 100%;
  // clear: both;
  .yritys-title {
    font-size: 1.2em;
    font-weight: 700;
  }
  li {
    margin: 4px 0 6px 0;
    line-height: 1.2;
  }
  .content-li {
    margin: 10px 0 0 0;
  }
}
