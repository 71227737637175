
body {
  // min-height: 100%;
  // display: flex;
  // flex-direction: column;
  font-weight: 400;
}

.wrap {
  // flex: 1;
  margin-top: 30px;
  margin-bottom: 50px;
}

h1 {
  margin-bottom: 20px;
}

h2, h3, h4, h5, h6 {
  margin-bottom: 15px;
}

.container {
  width: 100%;

  @media (min-width: $screen-sm-min) {
    width: $container-sm;
  }
  @media (min-width: $screen-md-min) {
    width: $container-md;
  }
  @media (min-width: $screen-lg-min) {
    width: $container-lg;
  }
}

.banner {
  margin-top: 10px;

  @media (min-width: $screen-sm-min) {
    margin-top: 30px;
  }

  &__logo {
    @include make-xs-column(12);

    @media (min-width: $screen-sm-min) {
      display: table;
      margin-bottom: 30px;
    }

    a {
      display: block;

      @media (min-width: $screen-sm-min) {
        margin:0;
        vertical-align: middle;
        display: table-cell;
      }
      img {
        display: block;
        margin: 0 auto;

        @media (min-width: $screen-sm-min) {
          margin:0;
        }
      }
    }

    div {
      color: #666666;
      font-size: 1em;
      line-height: 1;
      text-align: center;
      margin-bottom: 7px;

      @media (min-width: $screen-sm-min) {
        font-size: 1.286em;
        line-height: 1.2;
        margin-bottom: 0;
        display: table-cell;
        vertical-align: middle;
        text-align: right;
      }

      p {
        margin: 0;
      }
    }

  }

}

.navbar {
  font-weight: 300;
  font-size: 1.429em;
  text-align: center;
  @media (min-width: $screen-sm-min) {
    font-size: 1.143em;
  }
  @media (min-width: $screen-md-min) {
    font-size: 1.429em;
  }



  .container {
    padding: 0;
  }

  .nav {
    float: none;
    display: table;
    margin-right: auto;
    margin-left: auto;

    >li {

      >a {
        @media (min-width: $screen-sm-min) {
          padding: 12px 12px;
        }
        @media (min-width: $screen-md-min) {
          padding: 12px 15px;
        }

      }

      li {
        font-size: 1.429em;
        text-align: center;
        @media (min-width: $screen-md-min) {
          font-size: 1.2em;
          text-align: left;
        }
        a {
          font-weight: 300;
        }
      }

    }

  }

  .navbar-toggle {
    position: relative;
    width: 100%;
    float: right;
    margin-right: 0;
    padding: 0px 10px;
    margin-top: 0;
    margin-bottom: 0;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
  }

  .navlines {
    display: inline-block;
  }

  .navbar-text {
    display: inline-block;
  }

}

.navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:focus, .navbar-default .navbar-nav>.open>a:hover {
  background-color: #1788ce;
}


.lastmenu {
  .dropdown-menu {
    right: 0;
    left: initial;
  }
}

.mobilebreak {

  @media (max-width: 480px) {
    width: 100%;

    .img-responsive {
      width: 100%;
    }
  }


}

.nosto {
  margin-bottom: 30px;

  a {
    &:focus, &:hover {
      text-decoration: none;
      color: #fff;
    }
  }

  .nosto-otsikko {
    background-color: $brand-primary;
    text-transform: uppercase;
    color: #fff;
    font-size: 16px;
    font-weight: 300;
    text-align: center;
    margin: 0;
    display: table;
    width: 100%;
    padding: 10px;
    @media (min-width: $screen-md-min) {
      font-size: 20px;
    }

    span {
      display: table-cell;
      vertical-align: middle;
    }

  }

}

.maincontent, .page-content {
  h1,h2,h3,h4,h5 {
    &:first-child {
      margin-top: 0;
    }
  }
}

.content-info {
  background-color: #53a8dc;
  color: #fff;
  padding-top:20px;
  padding-bottom: 10px;
  font-weight: 300;

  a {
    color: #fff;
    &:hover, &:focus {
      text-decoration: underline;
      color: #fff;
    }
  }
}

form.search-form {
  margin-bottom: 30px;

  .search-field {
    display: inline-block;
    vertical-align: middle;
    @media (min-width: $screen-sm-min) {
      min-width: 400px;
    }
  }

}

.side-search {
  // margin-top: 70px;

  .search-form label {
    font-weight: 400;
    width: 100%;
  }

  form.search-form .search-field {
    width:100%;
    margin-bottom: 10px;
  }

  h3 {
    margin-top: 0;
  }

  .page-header {
    .yrityslajittelu:first-child {
        border-left: 0px solid #ccc;
        padding-left: 0px;
        margin-left: 0px;
        display: inline-block;
    }
  }
}

.wrap-image {
  img {
    width: 100%;
  }
}
