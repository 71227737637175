// Grid system
.main {
  @include make-sm-column($main-sm-columns);
  .sidebar-primary & {
    @include make-sm-column($main-sm-columns - $sidebar-sm-columns);
  }
}
.sidebar {
  @include make-sm-column($sidebar-sm-columns);
}

.single.sidebar-primary .main {
  @include make-sm-column($main-sm-columns - $sidebar-single-sm-columns);
}
.single .sidebar {
  @include make-sm-column($sidebar-single-sm-columns);
}


.home.sidebar-primary .main {
  @include make-sm-column($main-home-sm-columns - $sidebar-home-sm-columns);
}
.home .sidebar {
  @include make-sm-column($sidebar-home-sm-columns);
}
